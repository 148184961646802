<template>
  <div class="tw-payment-form-element w-full flex-col overflow-hidden transition-all">
    <FormError
      v-if="pageData.paymentFormError"
      :is-editor-mode="isEditorMode"
      :page-data="pageData"
      :page-options="pageOptions"
      :locale="locale"
    />
    <template v-else>
      <ProviderTabs
        v-if="multiPaymentMethod"
        v-model="activeTab"
        class="p-8 md:px-24 md:pb-8 md:pt-24"
        :locale="locale"
        :submit-button-element="submitButtonElement"
        :page-options="pageOptions"
        :get-provider-status="getProviderStatus"
        :dark-mode="!!element.options?.darkModeOnTabButtons"
        :disabled="isSubmitLoading || isLoadingPackage || isSubmitDisabled"
      />

      <div class="p-8 md:p-24" :class="{ 'flex flex-1': !hasPaymentMethod || pageData.isSuccessLoading }">
        <component
          :is="isEditorMode ? 'div' : WebForm"
          ref="paymentFormElement"
          @[formSubmitEvent]="clickProvider(
            pageData.providerStatus[STRIPE] ? STRIPE : CREDIT_CARD
          )"
        >
          <WebSpinner v-if="pageData.isSuccessLoading" class="flex min-h-[530px] flex-col items-center justify-center" />
          <template v-else-if="hasPaymentMethod">
            <template v-if="activeTab === CREDIT_CARD">
              <PaymentStripeInputs
                v-if="getProviderStatus(STRIPE)"
                ref="stripeForm"
                v-model="form"
                :element="element"
                :locale="locale"
                :page-data="pageData"
                :page-options="pageOptions"
                :consent-style="consentStyle"
                :is-editor-mode="isEditorMode"
                :stripe-token="pageData.stripeToken"
              />
              <PaymentFormInputs
                v-else-if="getProviderStatus(CREDIT_CARD)"
                v-model="form"
                :page-options="pageOptions"
                :is-label-visible="isLabelVisible"
                :label-style="labelStyle"
                :consent-style="consentStyle"
                :locale="locale"
                :is-editor-mode="isEditorMode"
                :page-data="pageData"
                :element="element"
                :disabled="isSubmitLoading || isLoadingPackage || isSubmitDisabled"
              />
            </template>
            <RegisterInput
              v-else-if="pageData.registerOnPayment"
              key="otherPaymentRegisterInput"
              v-model:subscriber-id="form.email"
              :disabled="pageData.bypassRegister"
              name="OtherPayment_RegisterInput"
              hide-full-name-input
              class="mt-16"
              :locale="locale"
              :is-editor-mode="isEditorMode"
              :element="element"
              :page-data="pageData"
              :page-options="pageOptions"
              @error="(errMessage: string) => globalEmit('paymentFormInputError', errMessage)"
            />

            <template v-if="activeTab !== CREDIT_CARD">
              <AcceptPolicy
                v-if="(isEditorMode || pageData.isPolicyRequired)"
                v-model="form.acceptPolicy"
                :page-options="pageOptions"
                :page-data="pageData"
                :locale="locale"
                :consent-style="consentStyle"
                :input-class="{ 'pointer-events-none' : isEditorMode }"
                :is-editor-mode="isEditorMode"
                :disabled="isSubmitLoading || isLoadingPackage || isSubmitDisabled"
                class="mb-32 mt-16"
              />
            </template>

            <PriceInfo
              :page-options="pageOptions"
              :label-style="labelStyle"
              :total-price="totalPrice"
              :locale="locale"
              :is-editor-mode="isEditorMode"
              class="mb-32 mt-24 md:mt-32"
              :class="{ '!mt-8 md:!mt-0': activeTab !== CREDIT_CARD }"
            />
            <!-- DEFAULT SLOT (SUBMIT BUTTON) -->
            <div v-if="activeTab === CREDIT_CARD && $slots.default" class="tw-payment-form-element__button">
              <slot
                v-if="renderComponent"
                :locale="locale"
                :page-data="submitButtonProps"
                :page-options="pageOptions"
              />
            </div>

            <div
              v-if="isOtherPaymentMethodsActive"
              class="flex flex-col gap-16"
              :class="{ 'pointer-events-none': isEditorMode }"
            >
              <div v-if="activeTab === PAYPAL">
                <ProviderButton
                  :provider="PAYPAL"
                  :disabled="isSubmitLoading"
                  :loading="$wait.is(`${PAYPAL}:paymentProcessing`)"
                  class="!bg-[#F6C444]"
                  @[btnClick]="clickProvider(PAYPAL)"
                />
                <div class="mt-4 text-center text-12 font-semibold">
                  {{ translate('generate.pages.payment.paypalMotto', locale) }}
                </div>
              </div>

              <template
                v-if="(getProviderStatus(APPLE_PAY) || getProviderStatus(GOOGLE_PAY)) && activeTab === 'others'"
              >
                <template v-for="provider in [APPLE_PAY, GOOGLE_PAY]" :key="provider">
                  <ProviderButton
                    v-if="getProviderStatus(provider)"
                    :provider="provider"
                    :loading="$wait.is(`${provider}:paymentProcessing`)"
                    :disabled="isSubmitLoading"
                    :dark-mode="!!element.options?.darkModeOnTabButtons"
                    @[btnClick]="clickProvider(provider)"
                  />
                </template>
              </template>
            </div>

            <!-- INFO TEXT -->
            <div v-if="$slots.infoText" class="tw-payment-form-element__info-text mt-24">
              <slot
                name="infoText"
                :locale="locale"
                :page-data="pageData"
                :page-options="pageOptions"
              />
            </div>

            <!-- INFO TEXT 2 -->
            <div v-if="$slots.infoText2" class="tw-payment-form-element__info-text">
              <slot
                name="infoText2"
                :locale="locale"
                :page-data="pageData"
                :page-options="pageOptions"
              />
            </div>
          </template>
          
          <template v-else>
            <NoPaymentMethodInfo
              :is-editor-mode="isEditorMode"
              :locale="locale"
              :page-options="pageOptions"
            />
          </template>
        </component>
      </div>
    </template>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, type PropType, toRefs, watch } from 'vue';
import {
  PaymentFormInputs,
  PaymentStripeInputs,
  ProviderButton,
  PriceInfo,
  ProviderTabs,
  NoPaymentMethodInfo
} from '@shared/elements/common/payment/components';
import { PaymentProvider, type PaymentElementTabs, type PaymentPageData } from '@shared/elements/common/payment/types';
import { WebForm, WebSpinner } from '@shared/components';
import { $wait } from '@shared/utils/wait';
import type { Element as ElementType, PageOptions } from '@shared/types/model';
import type { ElementOptions } from '@shared/types/options';
import { useTranslate } from '@shared/composable/useTranslate';
import { usePaymentElement } from '@shared/elements/common/payment/composables/usePaymentElement';
import FormError from '@shared/elements/common/payment/components/form-error/index.vue';
import RegisterInput from '@shared/elements/common/registration/components/registerInput.vue';
import { globalEmit } from '@shared/utils/helpers';
import AcceptPolicy from '@shared/elements/common/payment/components/accept-policy/index.vue';

const props = defineProps({
  isEditorMode: { type: Boolean, default: true },
  config: { type: Object as PropType<ElementOptions['payment-with-tabs']>, default: () => ({}) },
  pageData: { type: Object as PropType<PaymentPageData>, default: () => ({}) },
  pageOptions: { type: Object as PropType<PageOptions>, default: () => ({}) },
  element: { type: Object as PropType<ElementType<'payment-with-tabs'>>, default: () => ({}) },
  locale: { type: String, default: '' }
});

const { translate } = useTranslate();

const { CREDIT_CARD, PAYPAL, GOOGLE_PAY, APPLE_PAY, STRIPE } = PaymentProvider;
const { element, pageOptions, pageData } = toRefs(props);
const activeTab = ref(setDefaultTab());

const formSubmitEvent = computed(() => {
  return activeTab.value !== CREDIT_CARD ? '' : 'submit';
})

watch(
  () => pageData.value.providerStatus,
  () => {
    activeTab.value = setDefaultTab();
  }
)

function setDefaultTab(): PaymentElementTabs {
  const provider = Object.keys(pageData.value.providerStatus).filter((key) => pageData.value.providerStatus[key as PaymentProvider])
    return provider.includes(PaymentProvider.CREDIT_CARD)|| provider.includes(PaymentProvider.STRIPE)
    ? PaymentProvider.CREDIT_CARD
    : provider.includes(PaymentProvider.PAYPAL) ? PaymentProvider.PAYPAL : 'others'
}

const {
  form,
  stripeForm,
  paymentFormElement,
  clickProvider,
  labelStyle,
  consentStyle,
  isLabelVisible,
  getProviderStatus,
  isOtherPaymentMethodsActive,
  totalPrice,
  isSubmitLoading,
  isSubmitDisabled,
  isLoadingPackage,
  submitButtonElement,
  hasPaymentMethod,
  forceRender,
  renderComponent
} = usePaymentElement({ element, pageData, pageOptions });

const btnClick = computed(() => (props.isEditorMode ? '' : 'click'));

const submitButtonProps = computed(() => ({
  isLoading: $wait.is(`${CREDIT_CARD}:paymentProcessing`),
  isDisabled: isSubmitDisabled.value,
  applyConditionalValue: props.pageData?.applyConditionalValue,
  getActiveCondition: props.pageData?.getActiveCondition
}));

const multiPaymentMethod = computed(
  () => Object.values(pageData.value?.providerStatus).filter((item) => item).length > 1
);

watch(
  () => submitButtonProps.value,
  forceRender,
)
</script>

<style lang="postcss" scoped>
.tw-payment-form-element {
  &__info-text {
    a {
      color: #007aff !important;
    }
  }
}

@media (max-width: 640px) {
  .tw-payment-form-element {
    @apply border-none w-full;
  }
}

/* For editor screen only */
.tw-editor__screen {
  &.mobile {
    .tw-payment-form-element {
      @apply border-none w-full;
    }
  }
}
</style>
